import React from "react";
import "../styles/Main.css";
import topBanner from "../assets/TopBanner.png";
import down from "../assets/Down.png";
import feature1 from "../assets/Feature1.png";
import feature2 from "../assets/Feature2.png";
import feature3 from "../assets/Feature3.png";
import feature4 from "../assets/Feature4.png";
import feature5 from "../assets/Feature5.png";
import bottomBanner from "../assets/BottomBanner.png";
import reviews from "../assets/Reviews.png";

const Main = () => {
  const handleDownloadClick = () => {
    window.open(
      "https://apps.apple.com/kr/app/%EC%8A%AC%EB%A6%BD%EB%A8%B8%EB%8B%88-%EC%9E%90%EB%A9%B4%EC%84%9C-%EB%8F%88-%EB%B2%84%EB%8A%94-%EC%B5%9C%EC%B4%88-%EC%95%B1%ED%85%8C%ED%81%AC-%EC%88%98%EB%A9%B4-%EA%B1%B4%EA%B0%95/id6471601548",
      "_blank"
    );
  };
  return (
    <div>
        <div className="top-banner">
          <h1>잠이 더 즐거워진다</h1>
          <h1>자면서 돈 버는 슬립머니</h1>
          <button className="download-button" onClick={handleDownloadClick}>
            앱 다운로드
          </button>
        </div>
        <div className="top-banner">
          <img src={topBanner} alt="top-banner" />
        </div>
        <div className="scroll-indicator">
          <img src={down} alt="scroll-indicator" />
        </div>
        <div className="feature-container">
          <h1>자기만 해도 쓸 수 있는</h1>
          <h1>슬립 포인트가 쌓여요</h1>
          <img src={feature1} alt="feature1" />
        </div>

        <div className="feature-container">
          <h1>수면 패턴을 지키면</h1>
          <h1>포인트가 2배로 팡팡</h1>
          <img src={feature2} alt="feature2" />
        </div>

        <div className="feature-container">
          <h1>매일 모인 슬립 포인트로</h1>
          <h1>기프티콘으로 교환하세요</h1>
          <img src={feature3} alt="feature3" />
        </div>

        <div className="feature-container">
          <h1>다양한 제휴점</h1>
          <h1>어에서나 가능해요</h1>
          <img src={feature4} alt="feature4" />
        </div>

        <div className="feature-container">
          <h1>내일 최고의 컨디션은</h1>
          <h1>슬립머니와 함께</h1>
          <img src={feature5} alt="feature5" />
        </div>

        <div className="bottom-banner">
          <h1>수면 건강, 이제 당신의</h1>
          <h1>진짜 자산이 됩니다</h1>
          <button className="download-button" onClick={handleDownloadClick}>
            시작하기
          </button>
        </div>

        <div className="bottom-banner">
          <img src={reviews} alt="review" style={{width: '80%'}} />
        </div>
        <div className="bottom-banner">
          <img src={bottomBanner} alt="bottom-banner" style={{width: '80%'}} />
        </div>
    </div>
  );
};

export default Main;
