import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>라이징슬립</h4>
        <a href="https://risingsleep.framer.website/" target="_blank">
          회사 소개
        </a>
      </div>
      <div className="footer-section">
        <h4>서비스 정보</h4>
        <a
          href="https://risingsleep.notion.site/d79eb5a5fa754cb09364b3815944f2d9"
          target="_blank"
        >
          서비스 이용약관
        </a>
        <br />
        <a
          href="https://risingsleep.notion.site/28c96b2e72054407a0a567119127185e?pvs=4"
          target="_blank"
        >
          개인정보 처리방침
        </a>
      </div>
      <div className="footer-section">
        <h4>고객센터</h4>
        <a href="https://pf.kakao.com/_mxjybG" target="_blank">
          카카오톡 고객센터
        </a>
      </div>
      <div className="footer-section">
        <h4>사업 문의</h4>
        <p>hello@risingsleep.io</p>
      </div>
      <div className="footer-bottom">
        <p>ⓒ Risingsleep Inc. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
