import React, { useState } from "react";
import "../styles/Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu">
      <div
        className={`hamburger-icon ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <a href="https://risingsleep.framer.website/" target="_blank">
          회사소개
        </a>
        <a
          href="https://apps.apple.com/kr/app/%EC%8A%AC%EB%A6%BD%EB%A8%B8%EB%8B%88-%EC%9E%90%EB%A9%B4%EC%84%9C-%EB%8F%88-%EB%B2%84%EB%8A%94-%EC%B5%9C%EC%B4%88-%EC%95%B1%ED%85%8C%ED%81%AC-%EC%88%98%EB%A9%B4-%EA%B1%B4%EA%B0%95/id6471601548"
          target="_blank"
        >
          시작하기
        </a>
      </div>
    </div>
  );
};

export default Header;
